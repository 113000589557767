.ContactosDiv{
    width: 50vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border-radius: 10px;
}
.ContactosDivOut{
    display: flex;
    justify-content: center;
}
.ContactosFields{
    width: 40vw;
}
.ContactosBtn{
    margin-top: 2vh !important;
}