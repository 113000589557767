@media screen and (max-width: 1400px) {
    .MarcacoesCard{
        width: 70vw !important;
        margin-bottom: 2vh;
        margin-right: 1vh;
        margin-left: 1vh;
    }
    .MarcacoesCardTypography{
        font-size: 10px !important;
        padding-left: .5vw;
    }
    .infoContacto{
        font-size: 10px !important;
        padding-left:1vw !important;
    }
    .MarcacoesCardTypography2{
        font-size: 10px !important;
    }
    .MarcacoesCardPriceText{
        font-weight: bold !important;
        font-size: 20px !important;
    }
    .peixePhoto{
        position: absolute;
        padding-left: 40vw !important;
        padding-top: 0vh !important;
        width: 100% !important;
        height: auto !important;
        max-width: 15vw !important;
    }
    .peixePhoto{
        visibility: hidden !important;
    }
}

.MarcacoesCard{
    width: 35vw;
    margin-bottom: 2vh;
    margin-right: 1vh;
    margin-left: 1vh;
}
.MarcacoesCardDiv{
    width: 76vw;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
}
.MarcacoesCardEntry{
    display: flex;
}
.MarcacoesFlex{
    display: flex;
    justify-content: space-between;
}
.MarcacoesCardTitle{
    padding-top: 1vh;
    font-weight: bold;
    font-size: 25px;
}
.MarcacoesCardReserva{
    color: blue !important;
}
.MarcacoesCardDivOut{
    width: 100%;
    display: flex;
    justify-content: center;
}
.MarcacoesCardTypography{
    padding-left: .5vw;
}
.peixePhoto{
    position: absolute;
    padding-left: 25vw;
    padding-top: 20vh;
    width: 100%;
    height: auto;
    max-width: 7vw;
}
.MarcacoesCardContent{
    display: flex;
    flex-direction: column;
    padding: 0px !important;
    padding-left: 1vw !important;
    padding-right: 1vw !important;
    height: 100%;
}
.infoContacto{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 2;
}
.infoContactoIn{
    padding-bottom: 1vh;
}
