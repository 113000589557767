@media screen and (max-width: 1400px) {
    .MenuBarButtonGridItem{
        width: 100%;
    }
    .ENGImg{
        padding-top: 15vh;
        height: 5vh !important;
    }
    .FaceImgIn{
        padding-top: 15vh;
        height: 5vh !important;
    }
    .InstaImgIn{
        padding-top: 15vh;
        height: 5vh !important;
    }
    .PTImg{
        padding-top: 15vh;
        height: 5vh !important;
    }
}
.MenuBar{
    margin: 1vh 1vh 1vh 1vh;
    background-color: rgb(61, 127, 251);
    min-height: 3.5vh;
    padding-left: 1vw;
}
.MenuBarGrid{
    height: auto;
}
.menuBarButton{
    color: white !important;
    background-color: black !important;
}
.menuBarBtn{
    cursor: pointer;
    height: 100%; 
    font-size: 20px;
    display: flex;
    align-items: center;
    padding-right: 1vw;
}
.MenuBarButtonGridItem{
    height: 3.5vh;
    cursor: pointer;
}
.buttons{
    display: flex;
    justify-content: flex-end;
    height: 3.5vh;
}
.PTImg{
    height: 2.5vh;
}
.ENGImg{
    height: 2.5vh;
}
.ENGButton{
    height: 2.5vh;
}
.InstaImgOut{
    right: 2vw;
}
.FaceImgOut{
    right: 1.5vw;
}
.InstaImgIn{
    height: 2.5vh;
}
.FaceImgIn{
    height: 2.5vh;
}