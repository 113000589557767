.MainDiv{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.QuemSomosText{
    padding-top: 5vh;
    padding-left: 20vw;
    padding-right: 20vw;
    font-size: 20px;
    text-justify: inter-word;
    text-align: justify;
    color: white;
}

.QuemSomosTextFim{
    padding-top: 5vh;
    padding-left: 20vw;
    padding-right: 20vw;
    padding-bottom: 5vh;
    font-size: 20px;
    text-justify: inter-word;
    text-align: justify;
    color: white;
}

.QuemSomosTextTitulo{
    padding-top: 5vh;
    padding-left: 20vw;
    padding-right: 20vw;
    font-size: 25px;
    text-justify: inter-word;
    text-align: justify;
    color: white;
}