.SponsorDivOut{
    display: flex;
    justify-content: center;
    align-items: center;
}
.SponsorDivIn{
    height: 25vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 60%;
    background-color: white;
    border-radius: 10px;
}
.SponsorImg{
    cursor: pointer;
    height: 20vh;
}
.SponsorImgCara{
    height: 15vh;
}