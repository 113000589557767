.GaleriaDiv{
    padding-top: 2vh;
}
.BarcoTexto{
    padding-top: 5vh;
    padding-left: 20vw;
    padding-right: 20vw;
    font-size: 20px;
    text-justify: inter-word;
    text-align: justify;
    color: white;
}
.Footer{
    position: absolute;
    width: 100%;
    bottom: 0;
}