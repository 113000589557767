.GaleriaImgOut{
    width: 25vw;
    height: 25vh;
    margin-top: 2vh;
    margin-right: 1vw;
}
.GaleriaCardMedia{
    height: 25vh;
}
.GaleriaImgIn{
    width: 40vw;
}
.GaleriaDiv{
    margin-left: 10vw;
    margin-right: 10vw;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 20vh;
}
.GaleriaModal{
    display: flex;
    justify-content: center;
}
.ModalImg{
    margin-top: auto;
    margin-bottom: auto;
    width: 50vw;
}
.GaleriaModalDiv{
    width: 50vw;
    margin-top: auto;
    margin-bottom: auto;
}
.GaleriaModalCross{
    position: absolute;
    right: 25vw;
}
.GaleriaModalNextBtn{
    position: absolute;
    bottom: 50%;
    right: 25vw;
}
.GaleriaModalPreviousBtn{
    position: absolute;
    bottom: 50%;
}
.GaleriaModalImg{
    height:3vh;
}
.GaleriaModalImgSides{
    height:4vh;
}