.TopBar{
    background-color:rgb(178, 178, 178);
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.divL{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}
.leftImg{
    height: 22vh;
    cursor: pointer;
    width: 100%;
    object-fit: contain;
    display: flex;
    justify-content: center;
}
.centerImg{
    width: 100%;
    display: flex;
    justify-content: center;
}
.centerImgIn{
    height: 25vh;
    width: 100%;
    object-fit: cover;
}
.logoFronteiraDiv{
    display: flex;
    justify-content: center;
    width: 100%;
}
.logoFronteira{
    cursor: pointer;
    height:20vh;
    object-fit: contain;
}

