.BottomBar{
    background-color:rgb(178, 178, 178);
    height: 20vh;
    width: 100%;
    align-items: center;
    margin-top: auto;
}
.bottomBarRight{
    display: flex;
    justify-content: center;
}
.bottomBarRightImg{
    height: 8vh;
}
.bottomBarLeft{
    display: flex;
    justify-content: center;
}
.bottomBarLeftImg{
    height: 10vh;
}
.BottomBarText{
    font-family: 'Courier New', Courier, monospace;
    display: flex;
    justify-content: center;
}