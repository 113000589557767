.backGround{
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 100vh;
}
.mainPhotoDiv{
    display: flex;
    justify-content: center;
}
.mainPhoto{
    height: 40vw;
}
